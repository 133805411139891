<template>
    <div
        :class="{
            [$style['block']]: true,
            [$style['block_inverted']]: inverted
        }"
    >
        <h2
            :class="[$style['block__title'], 'sw-title']"
            v-html="title"
        />

        <CollapseBox
            v-for="({ subtitle, text }, index) in content"
            :key="index"
            :class="$style['block__item']"
            :expanded="activeIndex === index"
            :inverted="inverted"
            @click="open(index)"
        >
            <template #title>
                {{ subtitle }}
            </template>

            <div v-html="text" />
        </CollapseBox>
    </div>
</template>

<script>
import CollapseBox from './TextInAccordion/CollapseBox'

export default {
  name: 'TextInAccordion',

  components: {
    CollapseBox
  },

  props: {
    title: {
      type: String,
      default: 'Заголовок текстового блока'
    },

    content: {
      type: Array,
      default: () => [{
        subtitle: 'Раскрывающийся текст',
        text: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
      }, {
        subtitle: 'Раскрывающийся текст',
        text: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
      }, {
        subtitle: 'Раскрывающийся текст',
        text: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
      }, {
        subtitle: 'Раскрывающийся текст',
        text: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
      }, {
        subtitle: 'Раскрывающийся текст',
        text: 'Используйте текст над изображением, чтобы дать вашим клиентам представление о вашем бренде. Выберите изображение и текст, который относится к вашему стилю и истории.'
      }]
    },

    inverted: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    activeIndex: null
  }),

  methods: {
    open (index) {
      this.activeIndex = this.activeIndex === index ? null : index
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.block {
  width: 100%;
  max-width: 516px;
  margin: auto;

  &__title {
    display: block;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 32px;
    @media (max-width: @bp-mobile-small) {
      text-align: left;
    }
  }

  &_inverted &__title { color: #FFFFFF; }

  &__item {
    border-bottom: 1px solid @grey-500;

    & + & {
      margin-top: 16px;
    }
  }
}
</style>

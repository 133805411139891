<template>
    <div>
        <div
            :class="{
                [$style['header']]: true,
                [$style['header_active']]: expanded,
                [$style['header_inverted']]: inverted,
            }"
            v-on="$listeners"
        >
            <div :class="[$style['header__text'], 'sw-text']">
                <slot name="title" />
            </div>

            <SvgIconCaret :class="$style['header__caret']" />
        </div>

        <SwTransition name="fade">
            <div
                v-if="expanded"
                :class="{
                    [$style['body']]: true,
                    [$style['body_inverted']]: inverted,
                }"
            >
                <slot />
            </div>
        </SwTransition>
    </div>
</template>

<script>
import SvgIconCaret from '@@/framework/icons/caret-down.svg'

export default {
  name: 'CollapseBox',

  components: {
    SvgIconCaret
  },

  props: {
    name: {
      type: String,
      default: ''
    },

    expanded: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 8px 16px 0;
  font-family: var(--sw-font-texts);
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  cursor: pointer;

  &_active { color: var(--sw-color-main-500); }
  &_inverted { color: #FFFFFF; }
  &_active&_inverted { opacity: 0.65 }

  &__text {
    font-size: 16px;
    line-height: 24px;
  }

  &__caret {
    margin-left: 8px;
  }

  &_active &__caret {
    transform: rotate(180deg)
  }
}

.body {
  display: block;
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &_inverted { color: #FFFFFF; }
}
</style>
